import { FC } from "react";
import { PageRoutes } from "./pages";
import { BrowserRouter } from "react-router-dom";
import { defaultTheme } from "./theme";
import { EnvironmentContextProvider, TelemetryContextProvider } from "./context";
import { ErrorBoundary } from "./components/ErrorBoundary";
import { ChakraProvider, ChakraTheme, extendTheme, ToastProviderProps } from "@chakra-ui/react";
import { ScrollToTop } from "./components/ScrollToTop";
import { ConfigResponse, IEnvConfig } from "./api";
import { Fonts } from "@am-tax/fe-core";

const toastOptions: ToastProviderProps = {
    defaultOptions: {
        isClosable: true,
        position: "bottom",
    },
};

type AppProps = {
    sqId: string;
    theme: Partial<ChakraTheme> | null;
    config: ConfigResponse;
    envConfig: IEnvConfig;
};

export const App: FC<AppProps> = ({ sqId, config, envConfig, theme }) => {
    return (
        <ChakraProvider theme={extendTheme(theme || defaultTheme)} resetCSS toastOptions={toastOptions}>
            <Fonts />
            <BrowserRouter>
                <EnvironmentContextProvider sqId={sqId} config={config} envConfig={envConfig}>
                    <ScrollToTop />
                    <ErrorBoundary>
                        {import.meta.env.VITE_OFFLINE_MODE === "true" ? (
                            <PageRoutes />
                        ) : (
                            <TelemetryContextProvider>
                                <ErrorBoundary>
                                    <PageRoutes />
                                </ErrorBoundary>
                            </TelemetryContextProvider>
                        )}
                    </ErrorBoundary>
                </EnvironmentContextProvider>
            </BrowserRouter>
        </ChakraProvider>
    );
};
