﻿import { FunctionComponent, lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { LoadingPage } from "@am-tax/fe-core";
import { IntroPage } from "./IntroPage";
import { LoginPage } from "./LoginPage";
import { useTranslation } from "react-i18next";
import { SqIdContextProvider } from "../context/SqIdContext";
import { ReplyUrlContextProvider } from "../context/ReplyUrlContextProvider/ReplyUrlContextProvider";

const CandidateDetailsPage = lazy(() => import("./CandidateDetailsPage").then(module => ({ default: module.CandidateDetailsPage })));
const QuestionnairePage = lazy(() => import("./QuestionnairePage").then(module => ({ default: module.QuestionnairePage })));
const SignaturePage = lazy(() => import("./SignaturePage").then(module => ({ default: module.SignaturePage })));
const ConfirmationPage = lazy(() => import("./ConfirmationPage").then(module => ({ default: module.ConfirmationPage })));
const OptOutPage = lazy(() => import("./OptOutPage").then(module => ({ default: module.OptOutPage })));
const CandidateDashboardPage = lazy(() => import("./CandidateDashboardPage").then(module => ({ default: module.CandidateDashboardPage })));
const OptedOutPage = lazy(() => import("./OptedOutPage").then(module => ({ default: module.OptedOutPage })));
const AlreadyCompletedPage = lazy(() => import("./AlreadyCompletedPage").then(module => ({ default: module.AlreadyCompletedPage })));
const NotFound = lazy(() => import("../components/NotFound").then(module => ({ default: module.NotFound })));

export const PageRoutes: FunctionComponent = () => {
    const { t } = useTranslation();

    return (
        <Suspense fallback={<LoadingPage text={t("loadingText")} />}>
            <Routes>
                <Route path="" element={<ReplyUrlContextProvider />}>
                    <Route path=":sqId" element={<SqIdContextProvider />}>
                        <Route path="intro" element={<IntroPage />} />
                        <Route path="login" element={<LoginPage />} />
                        <Route path="candidate" element={<CandidateDetailsPage />} />
                        <Route path="questionnaire/:sectionId" element={<QuestionnairePage />} />
                        <Route path="signature" element={<SignaturePage />} />
                        <Route path="confirmation" element={<ConfirmationPage />} />
                        <Route path="optOut" element={<OptOutPage />} />
                        <Route path="dashboard" element={<CandidateDashboardPage />} />
                        <Route path="optOuted" element={<OptedOutPage />} />
                        <Route path="alreadyCompleted" element={<AlreadyCompletedPage />} />
                        <Route path="*" element={<NotFound />} />
                    </Route>
                    <Route path="Unauthorized" element={<div>Unauthorized</div>} />
                    <Route path="404" element={<NotFound />} />
                    <Route path="*" element={<NotFound />} />
                </Route>
            </Routes>
        </Suspense>
    );
};
